@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .scrollbar::-webkit-scrollbar {
        display: initial;
    }

    .scrollbar {
        -ms-overflow-style: initial; /* IE and Edge */
        scrollbar-width: initial; /* Firefox */
    }
}
*{
    font-family: Roboto, sans-serif;
}